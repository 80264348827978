import {Component, inject, OnInit} from '@angular/core';
import {ActivatedRouteSnapshot, ResolveFn} from '@angular/router';
import {
  getPageGridModel,
  IPageWithGridModel,
  PageWithGridComponent
} from '../../../oex-ui-kit/base/page-with-grid.component';
import {IAdminRole, IAdminUser} from '../../services/api.types';
import {APIService} from '../../services/api.service';
import {FilterComponent, IFilterDesc} from '../../../oex-ui-kit/components/filter/filter.component';
import {StorageService} from '../../services/storage.service';
import {GridComponent, IGridColumnDef, IGridConfig, IRowActionEvent} from '../../../oex-ui-kit/components/grid/grid';
import {NgSelectModule} from '@ng-select/ng-select';
import {DialogService} from '../../services/dialog.service';
import {ModalService} from '../../services/modal.service';
import {ScrollVisibilityDirective} from "../../../oex-ui-kit/directives/scroll-visibility.directive";

export interface IAdminUsersModel extends IPageWithGridModel<IAdminUser> {
  roles: IAdminRole[];
}

export const resolveAdminUsers: ResolveFn<IAdminUsersModel> = async (route: ActivatedRouteSnapshot) => {
  const api = inject(APIService);
  const st = inject(StorageService);
  const roleFilter = {
    id: 'roles', label: 'Roles', items: []
  } as IFilterDesc;
  const model: IAdminUsersModel = {
    roles: [],
    ...getPageGridModel<IAdminUser>(route, [
      roleFilter
    ])
  };
  FilterComponent.loadFilterValuesFromStorage(model.filters, st, 'csh-filters-users');

  let users: IAdminUser[] = [];
  let roles: IAdminRole[] = [];
  try {
    const fltRoles = model.filters?.[0]?.value;
    const [u, r] = await Promise.all([
      await api.getAdminUsersList(model.sorting, fltRoles ? [fltRoles] : []),
      await api.getAdminRoles()
    ]);
    users = u.user;
    roles = r.role;
  } catch (e) {
    console.error(e);
  }

  model.data = {
    total: users.length,
    pageSize: users.length,
    currPage: 1,
    items: users
  };
  model.roles = roles;
  roleFilter.items = roles.map(r => ({
    id: r.slug,
    value: r.name
  }));

  /*model.data.items.forEach(c => {
  });*/

  return model;
}

@Component({
  selector: 'st-user-management-page',
  imports: [
    ScrollVisibilityDirective,
    FilterComponent,
    GridComponent,
    NgSelectModule
  ],
  templateUrl: './user-management-page.component.html',
  styleUrl: './user-management-page.component.scss'
})
export class UserManagementPageComponent extends PageWithGridComponent<IAdminUsersModel> implements OnInit {
  private ds = inject(DialogService);
  private modal = inject(ModalService);
  private api = inject(APIService);

  override model!: IAdminUsersModel;
  columnsDef: IGridColumnDef[] = [
    {
      text: 'Name',
      field: 'name',
      sorting: 'n',
      editable: true,
      initialSorting: 'asc'
    },
    {
      text: 'Email',
      field: 'email',
      editable: true,
      sorting: 'e',
      initialSorting: 'asc'
    },
    {
      text: 'Deal Lead',
      field: 'deal_lead',
      editable: true,
      sorting: 'd',
      editorType: 'checkbox',
      template: 'checkbox'
    },
    {
      text: 'Responsible',
      field: 'responsible',
      editable: true,
      sorting: 'r',
      editorType: 'checkbox',
      template: 'checkbox'
    },
    {
      text: 'Assigner',
      field: 'assigner',
      editable: true,
      sorting: 'a',
      editorType: 'checkbox',
      template: 'checkbox'
    },
    {
      text: 'Roles',
      field: 'roles',
      editable: true,
      template: 'rolesColumn',
      editorType: 'dropdown',
      editorMultiple: true
    },
  ];
  conf: IGridConfig = {
    sortingField: 'n',
    sortingOrder: 'asc',
    highlightChangedCells: true,
    rowActions: [{id: 'delete', 'icon': '/assets/img/icon-delete.svg', tooltip: 'Delete user'}]
  }
  protected override resolver = resolveAdminUsers;

  override ngOnInit() {
    super.ngOnInit();
    this.data$.subscribe(d => {
      this.setupEditors();
    });
  }

  async save(g: GridComponent) {
    const items = GridComponent.getChangedRecords(this.model.data.items);
    if (!items.length) {
      this.toggleEditMode();
      return;
    }

    this.ps.show();
    try {
      await this.api.saveAdminUsers(items);
      g.save();
      this.toggleEditMode();
      void this.refreshData();
    } catch (e) {
      this.modal.showError(e);
    } finally {
      this.ps.hide();
    }
  }

  onGridAction(event: IRowActionEvent<IAdminUser>) {
    switch (event.action.id) {
      case 'delete':
        this.askForDeletion(event.data);
        break;
    }
  }

  addNewUser() {
    if (!this.isEdit()) {
      this.toggleEditMode();
    }
    setTimeout(() => {
      this.grid()?.addNewRecord({
        name: '',
        email: '',
        roles: [],
        assigner: false,
        deal_lead: false,
        responsible: false,
        never_login: true
      }, true);
    });

    /* this.newUser = {
       name: '',
       email: '',
       roles: [],
       assigner: false,
       deal_lead: false,
       responsible: false,
       never_login: true
     };
     this.model.data.items.splice(0, 0, this.newUser);
     this.model.data.pageSize++;
     this.model.data.total++;*/
  }

  private askForDeletion(user: IAdminUser) {
    void this.ds.showConfirmDialog(`Are you sure you want to delete "${user.name}" user?`, async () => {
      void this.deleteUser(user);
    });
  }

  private async deleteUser(user: IAdminUser) {
    const removeFromGrid = () => {
      const idx = this.model.data.items.indexOf(user);
      if (idx !== -1) {
        this.model.data.items.splice(idx, 1);
        this.grid()?.detectChanges();
      }
    }
    if (!user.id) {
      removeFromGrid();
      return;
    }
    this.ps.show();
    try {
      await this.api.deleteAdminUser(user.id);
      removeFromGrid();
    } catch (e) {
      this.modal.showError(e);
    } finally {
      this.ps.hide();
    }
  }

  private setupEditors() {
    const col = GridComponent.getColumn(this.columnsDef, 'roles');
    if (!col) {
      return;
    }

    col.editorItems = this.model.roles.map(r => ({
      id: r.slug,
      text: r.name
    }));
  }
}
